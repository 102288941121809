.Comment {
  margin-top: 5px;
  padding: 5px 10px 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  align-items: flex-start;
  background-color:white;
}
.Main {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.subHeader {
  font-size: x-small;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
}
.picture{
  max-height:100%;
  max-width:100%;
}
