.Backdrop__Backdrop__efy1y {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.TabItems__TabItems__3ca73 {
  height: 1.5rem;
  margin-top: 0.1875rem;
  padding: 0;
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
}

.TabItem__TabItem__1kO2B {
  margin-top: 0;
  margin-left: 0.09375rem;
  margin-right: 0.09375rem;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  border-right: 1px solid rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);

  width: 100%;
  height: 100%;
}

.TabItem__Active__3kqsn {
  background-color: rgba(42, 85, 164, 0.75);
  /* border-bottom: 2px solid #be1e2d; */
  color:white;
}

.comments__CommentPane__3A0QG {
  height: calc(100% - 4rem);
  padding: 5px 5px 5px 5px;
  overflow-y: scroll;
  background-color:rgba(43, 57, 144, 0.25);
}

.comment__Comment__38M2v {
  margin-top: 5px;
  padding: 5px 10px 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  align-items: flex-start;
  background-color:white;
}
.comment__Main__3pB84 {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.comment__subHeader__2vVQV {
  font-size: x-small;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
}
.comment__picture__3f4lt{
  max-height:100%;
  max-width:100%;
}

.Notification__message__2JHgD {

}

.Spinner__Loader__1DDwY {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ce2a35;
  background: linear-gradient(to right, #ce2a35 10%, rgba(206, 42, 53, 0) 42%);
  position: fixed;
  left: 0;
  top: 35%;
  bottom: 0;
  right: 0;
  z-index: 999;
  animation: Spinner__load3__3wkyi 1.4s infinite linear;
  transform: translateZ(0);
}
.Spinner__Loader__1DDwY:before {
  width: 50%;
  height: 50%;
  background: #ce2a35;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.Spinner__Loader__1DDwY:after {
  background: #ffffff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@keyframes Spinner__load3__3wkyi {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.photos__MediaPane__2F4Vm {
  height: calc(100% - 4rem);
  padding: 5px 5px 5px 5px;
}

.photos__ImageContainer__pv2qp {
  position: relative;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(43, 57, 144, 0.25);
}

.photos__CommentIcon__39Tyf {
  position: absolute;
  bottom: 1%;
  left: 1%;
}
.photos__Comment__3vs42 {
  position: absolute;
  bottom: 0;
  left: 10%;
  right: 10%;
  max-height: 30%;
  overflow-y: scroll;
  background-color: rgba(245, 245, 220, 0.75);
}

.photos__TrashIcon__2cty5 {
  position: absolute;
  bottom: 1%;
  right: 1%;
}

.photos__Image__gnf2- {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.photos__ListContainer__1yYzT {
  height: 20%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;
}

@media (min-width: 35.5rem) {
  .photos__MediaPane__2F4Vm {
    height: calc(100% - 4rem);
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;
  }

  .photos__ImageContainer__pv2qp {
    width: 85%;
    height: 100%;
  }

  .photos__ListContainer__1yYzT {
    width: 15%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: scroll;
  }
}

.photo__Media__1A5PK {
  margin: 2px;
  padding: 2px;
  height: 65px;
}

.properties__PropertiesPane__1FUJ4 {
  height: calc(100% - 4rem);
  /* margin-top: 5px;
  padding: 5px 10px 5px 10px; */
  padding: 5px 5px 5px 5px;
  overflow-y: scroll;
  background-color:rgba(43, 57, 144, 0.25);
}

.properties__Name__1VHb7 {
  font-weight: bolder;
}

.properties__propertyLayout__39WaV {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: .5rem;
  gap: .5rem;
  padding: 2px 5px 2px 5px;
}

.properties__propertyKey__2u8tG {
  font-weight: bold;
  display: inline-block;
  width: 100px;
  text-align: left;
}

.properties__propertyValue__1qTqd {
  color: inherit; 
}
.LayerControl__LayerControlPane__1598a {
  /* margin: 0.75rem auto; */
  width: 100%;
  text-align: center;
  padding: 0.5rem;
  height: 100%;
  overflow: auto;
}

.LayerControl__Layer__2V_-m {
  width: 100%;
}

.LayerControl__Layers__AOdCc {
  height: calc(100% - 6rem);
  margin: 0.5rem;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
}

@media (min-width: 37.5rem) {
  .LayerControl__Layer__2V_-m {
    width: 50%;
  }
}

.Legend__LegendPane__1yAeI {
  width: 100%;
  margin-left: 1rem;
}

.Legend__Entry__1P_b9 {
  flex-direction: row;
  display: flex;
}

.Legend__Label__3MFby {
  margin-left: 0.5rem;
}

.Legend__Box__17rkA {
  height: 1rem;
  width: 1rem;
}

.BatchDownload__BatchDownload__1m6Kl {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

.BatchDownload__Center__2FSCc {
  text-align: center;
}

