.TabItem {
  margin-top: 0;
  margin-left: 0.09375rem;
  margin-right: 0.09375rem;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  border-right: 1px solid rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);

  width: 100%;
  height: 100%;
}

.Active {
  background-color: rgba(42, 85, 164, 0.75);
  /* border-bottom: 2px solid #be1e2d; */
  color:white;
}
