.MediaPane {
  height: calc(100% - 4rem);
  padding: 5px 5px 5px 5px;
}

.ImageContainer {
  position: relative;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(43, 57, 144, 0.25);
}

.CommentIcon {
  position: absolute;
  bottom: 1%;
  left: 1%;
}
.Comment {
  position: absolute;
  bottom: 0;
  left: 10%;
  right: 10%;
  max-height: 30%;
  overflow-y: scroll;
  background-color: rgba(245, 245, 220, 0.75);
}

.TrashIcon {
  position: absolute;
  bottom: 1%;
  right: 1%;
}

.Image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.ListContainer {
  height: 20%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;
}

@media (min-width: 35.5rem) {
  .MediaPane {
    height: calc(100% - 4rem);
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;
  }

  .ImageContainer {
    width: 85%;
    height: 100%;
  }

  .ListContainer {
    width: 15%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: scroll;
  }
}
