.LegendPane {
  width: 100%;
  margin-left: 1rem;
}

.Entry {
  flex-direction: row;
  display: flex;
}

.Label {
  margin-left: 0.5rem;
}

.Box {
  height: 1rem;
  width: 1rem;
}
