.PropertiesPane {
  height: calc(100% - 4rem);
  /* margin-top: 5px;
  padding: 5px 10px 5px 10px; */
  padding: 5px 5px 5px 5px;
  overflow-y: scroll;
  background-color:rgba(43, 57, 144, 0.25);
}

.Name {
  font-weight: bolder;
}

.propertyLayout {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: .5rem;
  padding: 2px 5px 2px 5px;
}

.propertyKey {
  font-weight: bold;
  display: inline-block;
  width: 100px;
  text-align: left;
}

.propertyValue {
  color: inherit; 
}