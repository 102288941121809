.TabItems {
  height: 1.5rem;
  margin-top: 0.1875rem;
  padding: 0;
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
}
