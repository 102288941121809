.LayerControlPane {
  /* margin: 0.75rem auto; */
  width: 100%;
  text-align: center;
  padding: 0.5rem;
  height: 100%;
  overflow: auto;
}

.Layer {
  width: 100%;
}

.Layers {
  height: calc(100% - 6rem);
  margin: 0.5rem;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
}

@media (min-width: 37.5rem) {
  .Layer {
    width: 50%;
  }
}
